import { toast } from 'react-toastify'
import { DateTime } from 'luxon'
import Cookies from 'universal-cookie'
import { DEVICE_TYPE_DESKTOP, DEVICE_TYPE_MOBILE, WhatsAppPhone } from '@/config/constants'
import { useEffect } from 'react'
import { PartnerValidationErrors } from './constants'

const translateIdToStatus = (id) => {
    const status = {
        PENDING: 'Pendente',
        APPROVED: 'Aprovada',
        REJECTED: 'Rejeitada',
        CANCELED: 'Cancelada'
    }

    return status[id]
}

const translateIdToSchoolLevel = (id) => {
    const status = {
        STUDYING_EARLY_CHILDHOOD_EDUCATION: 'Cursando Educação Infantil',
        COMPLETE_EARLY_CHILDHOOD_EDUCATION: 'Educação Infantil Completa',
        STUDYING_ELEMENTARY_SCHOOL: 'Cursando Ensino Fundamental',
        COMPLETE_ELEMENTARY_SCHOOL: 'Ensino Fundamental Completo',
        ATTENDING_HIGH_SCHOOL: 'Cursando Ensino Médio',
        COMPLETE_HIGH_SCHOOL: 'Ensino Médio Completo',
        STUDYING_HIGHER_EDUCATION: 'Cursando Ensino Superior',
        COMPLETE_HIGHER_EDUCATION: 'Ensino Superior Completo',
        STUDYING_SPECIALIZATION: 'Cursando Especialização',
        COMPLETE_SPECIALIZATION: 'Especialização Completa',
        ATTENDING_MASTERS_DEGREE: 'Cursando Mestrado',
        COMPLETE_MASTERS: 'Mestrado Completo',
        STUDYING_DOCTORATE: 'Cursando Doutorado',
        FULL_DOCTORATE: 'Doutorado Completo',
        ATTENDING_POST_DOCTORATE: 'Cursando Pos Doutorado',
        POST_DOCTORATE_COMPLETE: 'Pos Doutorado Completo',
        ILLITERATE: 'Analfabeto',
        NO_SCHOOLING: 'Sem instrução escolar'
    }

    return status[id]
}

const OrderStatus = {
    pending: 'PENDING',
    approved: 'APPROVED',
    rejected: 'REJECTED',
    canceled: 'CANCELED'
}

const OrderTypeStatus = {
    OPEN: 'OPEN',
    DOCUMENT_REVIEW: 'DOCUMENT_REVIEW',
    CREDIT_REVIEW: 'CREDIT_REVIEW',
    HUMAN_RESOURCES: 'HUMAN_RESOURCES',
    ONBOARDING: 'ONBOARDING'
}

const translateIdToOrderStatus = (id) => {
    const status = {
        OPEN: 'Aberto',
        DOCUMENT_REVIEW: 'Análise de Documento',
        CREDIT_REVIEW: 'Análise de Crédito',
        HUMAN_RESOURCES: 'Recursos Humanos',
        ONBOARDING: 'Cadastro'
    }
    return status[id]
}

const translateIdToMaritalStatus = (id) => {
    const status = {
        SINGLE: 'Solteiro',
        MARRIED: 'Casado',
        DISENGAGED: 'Desquitado',
        DIVORCED: 'Divorciado',
        SEPARATE: 'Separado',
        WIDOWER: 'Viúvo',
        OTHERS: 'Outros',
        NOT_INFORMED: 'Não informado',
        MARITAL_BOND: 'Vinculo Conjugal'
    }
    return status[id]
}

const translateIdToGender = (id) => {
    const status = {
        MALE: 'Masculino',
        FEMALE: 'Feminino'
    }
    return status[id]
}

const translateIdToBankAccountType = (id) => {
    const status = {
        SAVINGS: 'Poupança',
        CURRENT_ACCOUNT: 'Corrente corrente'
    }
    return status[id]
}

const invalidRequired = (value) => {
    return value.length > 0 || Number.isInteger(value)
        ? ''
        : 'É necessário preencher o campo obrigatório.'
}

const invalidNumber = (value) => {
    return /[-+]?([0-9]*\.[0-9]+|[0-9]+)/.test(value)
        ? ''
        : 'Só é aceito o preenchimento dos campos com números.'
}

const isNotZero = (value) => {
    return value !== 0 ? '' : 'Só é aceito números acima de zero'
}

const normalizeCurrency = (string) => {
    if (!string) return string
    return string.replace(/[$-/:-?{-~!"^_`[\]]/, '')
}

export const statusCode = {
    success: 'success',
    error: 'error',
    info: 'info'
}

const handleResponseError = (e) => {
    if (!e.response || !e.response.data) {
        return {
            success: false,
            msg: 'Oops aconteceu um erro inesperado'
        }
    }
    const { data } = e.response
    if (data.errors) {
        return {
            success: false,
            msg: `Encontramos problemas nos seguintes campos:\n${data.errors.join('\n')}`
        }
    } else {
        if (data.error) {
            if (typeof data.error === 'object') {
                data.error = `${data.error[0].msg}: ${data.error[0].param}`
            }
        }
        return {
            success: false,
            msg: data.message || data.error || data
        }
    }
}

const handleBaseUrl = (envUrl) => {
    return envUrl ? envUrl : 'http://localhost:3000'
}

const registerCookie = (token, expires_in) => {
    const cookies = new Cookies()

    cookies.set('session', token, {
        expires: expires_in,
        path: '/',
        sameSite: 'strict'
    })
}

const onlyDigits = (value) => {
    if (!value) return value
    return value.replace(/\D/g, '')
}

const handleToast = (response) => {
    toast[response.status](response.msg)
}

const sumReducer = (accumulator, curr) => {
    return +accumulator.toFixed(2) + +curr.toFixed(2)
}

const UNKNOWN_STATUS_COLOR = '#787878'

const capitalizeString = (str) => {
    const toLower = str.toLowerCase()
    return str.charAt(0).toUpperCase() + toLower.slice(1)
}

export const sortBy = (items, field, order = 'asc') => {
    return items.sort((a, b) => {
        a = parseFloat(a[field]) || 0
        b = parseFloat(b[field]) || 0
        return a > b ? (order === 'asc' ? 1 : -1) : a < b ? (order === 'asc' ? -1 : 1) : 0
    })
}

export const sortISODateBy = (items, field, order = 'asc') => {
    return items.sort((a, b) => {
        a = parseInt(DateTime.fromISO(a[field]).toFormat('X'))
        b = parseInt(DateTime.fromISO(b[field]).toFormat('X'))
        return a > b ? (order === 'asc' ? 1 : -1) : a < b ? (order === 'asc' ? -1 : 1) : 0
    })
}

export const solveUserAgent = (req) => {
    return (req && req.headers['user-agent']) || (navigator && navigator.userAgent) || ''
}

export const getDeviceType = (userAgent) => {
    const isMobile = Boolean(
        userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)
    )
    return isMobile ? DEVICE_TYPE_MOBILE : DEVICE_TYPE_DESKTOP
}

export const getUserSession = () => {
    const cookies = new Cookies()
    return cookies.get('da-session')
}

export const generateWhatsAppLink = (numberType, message = '') => {
    const phone = WhatsAppPhone[numberType]?.phone || ''
    return `https://api.whatsapp.com/send/?phone=${phone}&text=${encodeURI(message)}`
}

export const useDebouncedEffect = (effect, deps, delay) => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay)

        return () => clearTimeout(handler)
    }, [...(deps || []), delay])
}

export const handlePartnerErrors = (code, setDuplicatedCpfError) => {
    if (code === PartnerValidationErrors.DUPLICATED_PARTNERS_CPF.code) {
        setDuplicatedCpfError(PartnerValidationErrors.DUPLICATED_PARTNERS_CPF.description)
        return
    }
}

export {
    translateIdToStatus,
    translateIdToSchoolLevel,
    translateIdToOrderStatus,
    translateIdToMaritalStatus,
    translateIdToGender,
    translateIdToBankAccountType,
    invalidRequired,
    invalidNumber,
    isNotZero,
    handleResponseError,
    handleBaseUrl,
    OrderStatus,
    OrderTypeStatus,
    registerCookie,
    handleToast,
    normalizeCurrency,
    sumReducer,
    onlyDigits,
    UNKNOWN_STATUS_COLOR,
    capitalizeString
}

export const PAGE_SIZE = 25

export const stringFromBase64 = (data) => {
    const buff = Buffer.from(data, 'base64')
    return buff.toString('utf-8')
}

export const maskCpf = (document) => {
    const firstThreeDigits = onlyDigits(document).slice(3, 6)
    const secondThreeDigits = onlyDigits(document).slice(6, 9)

    return `***.${firstThreeDigits}.${secondThreeDigits}-**`
}

export const maskCnpj = (document) => {
    const firstTwoDigits = onlyDigits(document).slice(0, 2)
    const lastSixDigits = onlyDigits(document).slice(-6)

    return `${firstTwoDigits}.***.***/${lastSixDigits.slice(0, 4)}-${lastSixDigits.slice(-2)}`
}

export const maskCpfCnpj = (document) => {
    if (!document) {
        return document
    }

    return document.length === 11 ? maskCpf(document) : maskCnpj(document)
}
