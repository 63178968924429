import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
import axios from 'axios'
import Cookies from 'universal-cookie'

const graphQLService = axios.create({
    baseURL: `${process.env.API_URL}/supply-bff/graphql`,
    delayed: true
})

// Add a request interceptor
graphQLService.interceptors.request.use(
    function (config) {
        const cookies = new Cookies()
        const token = cookies.get('da-session')
        config.headers.Authorization = 'Bearer ' + token

        NProgress.start()
        return config
    },
    function (error) {
        if (axios.isCancel(error)) {
            throw new Error(error)
        }
        NProgress.done()
        return Promise.reject(error)
    }
)

// Add a response interceptor
graphQLService.interceptors.response.use(
    function (response) {
        NProgress.done()
        return response
    },
    function (error) {
        if (axios.isCancel(error)) {
            throw new Error(error)
        }
        NProgress.done()
        if (error.response.status === 401) {
            const cookies = new Cookies()
            cookies.remove('da-session')
            localStorage.removeItem('name')
            window.location.href = '/digital-account/new-login'
        }
        return Promise.reject(error)
    }
)

export default graphQLService
